import * as React from 'react';

import { Home, Layout, NorthCarolina, Shared } from '../../components';

const NorthCarolinaPage = () => {
  return (
    <>
      <Layout.NavMenu />
      <Home.HeroImage />
      <NorthCarolina.PropertyTaxServices />
      <NorthCarolina.MecklenburgPropertyValues />
      <Home.WhyChooseUs />
      <Shared.WhyAppeal />
      <Shared.TaxAppealsMadeEasy />
      <Shared.CaseStudiesResidential />
      <Shared.CaseStudiesCommercial />
      <Home.OurCompany />
      <Home.Contact />
      <Layout.Footer />
    </>
  );
};

export default NorthCarolinaPage;

export const Head = () => <Layout.PageHead subtitle="North Carolina" />;
